<template>
  <div class="bg-gray wrapper-bottombar">
    <div class="bottombar flex justify-between items-center">
      <router-link v-for="link in links" :key="link.url" class="sidebar-item flex items-center" active-class="active"
        :to="link.url" :exact="link.exact">
        <i :class="'icon-' + link.icon"></i>{{ link.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    links: [
      { title: 'Home', url: '/', icon: 'home', exact: true },
      { title: 'Trade', url: '/trade', icon: 'trade' },
      { title: 'Order', url: '/order', icon: 'news' },
      { title: 'Profile', url: '/account', icon: 'account' },
      { title: 'Overview', url: '/overview', icon: 'overview', exact: true }
    ]
  })
}
</script>

<style scoped>
.icon {
  fill: #888b9a;
}

.icon:hover {
  fill: #5176CF;
}

.logout-text {
  padding-left: 7px;
}
</style>
