<template>
  <div class="bg-gray wrapper-sidebar">
    <div class="sidebar">
      <router-link to="/" class="logo">
        <img src="img/icons/Asset-1@72x-8.png" alt="LOGO">
      </router-link>
      <router-link
        v-for="link in links"
        :key="link.url"
        class="sidebar-item flex items-center"
        active-class="active"
        :to="link.url"
        :exact="link.exact"
      >
        <i :class="'icon-' + link.icon"></i>{{ $t(link.title) }}
      </router-link>
      <LogOut/>
    </div>
  </div>
</template>

<script>
import LogOut from '@/components/buttons/LogOut.vue'

export default {
  components: {
    LogOut
  },
  data: () => ({
    links: [
      { title: 'sidebar.home', url: '/', icon: 'home', exact: true },
      { title: 'sidebar.trade', url: '/trade', icon: 'trade' },
      { title: 'sidebar.order', url: '/order', icon: 'news' },
      { title: 'sidebar.profile', url: '/account', icon: 'account' },
      { title: 'sidebar.overview', url: '/overview', icon: 'overview' }
      // { title: '客服', url: '/support', icon: 'support' }
    ]
  }),
  methods: {
    // handleLogout () {
    //   this.$store.dispatch('logout')
    // }
  }
}
</script>

<!-- <style scoped>
.icon {
  fill: #888b9a;
}
.icon:hover {
  fill: #5176CF;
}
.logout-text {
  padding-left: 7px
}
</style> -->
