import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Home',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/dashboard/Home.vue')
  },
  {
    path: '/trade',
    name: 'Trade',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/Trade.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/News.vue')
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/Account.vue')
  },
  {
    path: '/order',
    name: 'Order',
    meta: {
      layout: 'main',
      title: 'Buy Put / Call Order',
      auth: true
    },
    component: () => import('../views/Order.vue')
  },
  {
    path: '/details',
    name: 'Details',
    meta: {
      layout: 'main',
      title: 'Order details',
      auth: true
    },
    component: () => import('../views/Details.vue')
  },
  {
    path: '/support',
    name: 'Support',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/Support.vue')
  },
  {
    path: '/spot',
    name: 'Spot',
    meta: {
      layout: 'main',
      title: 'Spot',
      admin: true,
      auth: true
    },
    component: () => import('../views/Spot.vue')
  },
  {
    path: '/future',
    name: 'Future',
    meta: {
      layout: 'main',
      title: 'Future',
      admin: true,
      auth: true
    },
    component: () => import('../views/Future.vue')
  },
  // {
  //   path: '/deposit',
  //   name: 'Deposit',
  //   meta: {
  //     layout: 'main',
  //     auth: true
  //   },
  //   component: () => import('../views/dashboard/Deposit.vue')
  // },
  {
    path: '/deposit-history',
    name: 'DepositHistory',
    meta: {
      layout: 'main',
      title: 'Deposit History',
      admin: true,
      auth: true
    },
    component: () => import('../views/DepositHistory.vue')
  },
  // {
  //   path: '/history',
  //   name: 'History',
  //   meta: {
  //     layout: 'main',
  //     auth: true
  //   },
  //   component: () => import('../views/dashboard/History.vue')
  // },
  // {
  //   path: '/withdrawl',
  //   name: 'Withdrawl',
  //   meta: {
  //     layout: 'main',
  //     auth: true
  //   },
  //   component: () => import('../views/dashboard/Withdrawl.vue')
  // },
  {
    path: '/security',
    name: 'Security',
    meta: {
      layout: 'main',
      title: 'Security',
      auth: true
    },
    component: () => import('../views/Security.vue')
  },
  {
    path: '/overview',
    name: 'Overview',
    meta: {
      layout: 'main',
      title: 'Overview',
      admin: true,
      auth: true
    },
    component: () => import('../views/Overview.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'empty', auth: false },
    component: () => import('../views/emptyLayout/Login.vue')
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    meta: { layout: 'empty', auth: false },
    component: () => import('../views/emptyLayout/SignUp.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasssword',
    meta: { layout: 'empty', auth: false },
    component: () => import('../views/emptyLayout/ForgotPassword.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, _, next) => {
  const auth = await store.dispatch('auth')
  if (to.meta.auth) {
    auth ? next() : next('/login')
  } else {
    if (!auth) {
      store.commit('RESET_LOGIN_SIGNUP_FORGET_STATE')
      next()
    } else next('/')
  }
})

export default router
