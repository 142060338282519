import Vue from 'vue'
import Vuex from 'vuex'
import * as auth from './modules/auth'
import * as dashboard from './modules/dashboard'
import * as trade from './modules/trade'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    dashboard,
    trade
  }
})
