<template>
    <span class="logout cursor-pointer sidebar-item flex items-center icon" @click="handleLogout">
        <svg width="20" height="20" viewBox="0 0 40 30" class="inline-block mr-1">
            <use xlink:href="#icon-exit"></use>
            <symbol id="icon-exit">
                <path d="M24 20v-4h-10v-4h10v-4l6 6zM22 18v8h-10v6l-12-6v-26h22v10h-2v-8h-16l8 4v18h8v-6z"></path>
            </symbol>
        </svg>
        <span class="logout-text">{{ $t('sidebar.logout') }}</span></span>
</template>

<script>
export default {
  name: 'LogOut',
  methods: {
    handleLogout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style scoped>
.icon {
    fill: #888b9a;
}

.icon:hover {
    fill: #5176CF;
}

.logout-text {
    padding-left: 7px
}
</style>
