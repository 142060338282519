<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'
export default {
  data: () => ({
    layout: ''
  }),
  watch: {
    $route () {
      this.layout = (this.$route.meta.layout || 'empty') + '-layout'
    }
  },
  // computed: {
  //   layout () {
  //     console.log(this.$route.meta.layout)
  //     return (this.$route.meta.layout || 'empty') + '-layout'
  //   }
  // },
  components: {
    EmptyLayout, MainLayout
  }
}
</script>

<style lang="scss">

#app {
  height: 100%;
}
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #888B9A;
    -webkit-box-shadow: 0 0 0px 1000px #101116 inset;
  }
</style>
