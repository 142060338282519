import { currencyLive, currencySummary, depositRequest, currencyReport } from '../api'
import axios from 'axios'
import Vue from 'vue'
import router from '@/router'

const coins = [
  { name: 'BTCUSDT' },
  { name: 'ETHUSDT' }
]

const bufferBigData = {
  live: {},
  hour: {},
  day: {},
  week: {},
  month: {},
  year: {}
}

for (const key in bufferBigData) {
  if (Object.hasOwnProperty.call(bufferBigData, key)) {
    for (const coin of coins) {
      bufferBigData[key][coin.name] = {
        data: [],
        lables: []
      }
    }
  }
}

export const state = function () {
  return {
    wsLive: undefined,
    wsSummary: undefined,
    liveData: {},
    summaryData: {},
    currentCurrencyType: 'BTCUSDT',
    errors: null,
    loading: true,
    bufferBigData,
    coins
  }
}

const storeSate = state()

export const getters = {
  getbufferBigData: (state) => (tab) => {
    return state.bufferBigData[tab][state.currentCurrencyType]
  }
}

export const actions = {

  subscribeCoin ({ state, commit }, { stateType, coin }) {
    const dataType = stateType === 'wsLive' ? 'liveData' : 'summaryData'
    if (!(coin in state[dataType])) {
      commit('LOADING', true)
      state[stateType].send(JSON.stringify({ event: 'subscribe', currency_type: coin }))
    }
  },

  unsubscribeCoin ({ state }, { stateType, coin }) {
    state[stateType].send(JSON.stringify({ event: 'unsubscribe', currency_type: coin }))
  },

  async get_live_currency ({ state, commit, dispatch }) {
    try {
      const token = window.sessionStorage.getItem('token')
      const url = `${currencyLive}?token=${token}`
      state.wsLive = new WebSocket(url)
      state.wsLive.onmessage = ev => {
        ev.data?.length > 30 && commit('SET_WS_DATA', {
          key: 'liveData',
          value: ev.data,
          stateType: 'wsLive'
        }) && commit('LOADING', false)
      }
      state.wsLive.onerror = ev => {
        console.log('error')
      }
      state.wsLive.onopen = ev => {
        dispatch('subscribeCoin', {
          stateType: 'wsLive',
          coin: state.currentCurrencyType
        })
      }
      state.wsLive.onclose = ev => {
        console.log('close')
      }
    } catch (err) {
      console.log(err)
    }
  },

  async get_summary_currency ({ state, commit, dispatch }) {
    try {
      const token = window.sessionStorage.getItem('token')
      const url = `${currencySummary}?token=${token}`
      state.wsSummary = new WebSocket(url)
      state.wsSummary.onmessage = ev => {
        ev.data?.length > 30 && commit('SET_WS_DATA', {
          key: 'summaryData',
          value: ev.data,
          stateType: 'wsSummary'
        })
      }
      state.wsSummary.onerror = ev => {
        console.log('error')
      }
      state.wsSummary.onopen = ev => {
        dispatch('subscribeCoin', {
          stateType: 'wsSummary',
          coin: state.currentCurrencyType
        })
      }
      state.wsSummary.onclose = ev => {
        console.log('close')
      }
    } catch (err) {
      console.log(err)
    }
  },

  ws_dashboard_unsubscribe ({ state, dispatch, commit }) {
    Object.keys(state.liveData).forEach(coin =>
      dispatch('unsubscribeCoin', { stateType: 'wsLive', coin })
    )
    state.wsLive.close()

    Object.keys(state.summaryData).forEach(coin =>
      dispatch('unsubscribeCoin', { stateType: 'wsSummary', coin })
    )
    state.wsSummary.close()

    commit('LOGOUT_WS')
  },

  async deposit_request ({ state }, obj) {
    const token = window.sessionStorage.getItem('token')
    return await axios.post(depositRequest, obj, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  },

  async currency_report ({ state, commit }, currencyObj) {
    state.errors = null
    const check = state.bufferBigData[currencyObj.store.lable][state.currentCurrencyType].data

    if (currencyObj.store.lable !== 'hour' && check.length !== 0) {
      return
    }
    commit('LOADING', true)
    const token = window.sessionStorage.getItem('token')
    const result = await axios.post(currencyReport, currencyObj.api, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => response.data.pricing_report_array.filter(item => item.buy_price !== null))
      .catch(error => { state.errors = error })

    commit('UPDATE_BUFFER_BIG_DATA', {
      key: currencyObj.store.lable,
      coin: currencyObj.api.currency,
      value: result ?? []
    })
    commit('LOADING', false)
  },

  changeCoin ({ commit, dispatch }, coin) {
    commit('CHANGE_CURRENT_COIN', coin)
    dispatch('subscribeCoin', { stateType: 'wsLive', coin })
    dispatch('subscribeCoin', { stateType: 'wsSummary', coin })
  }
}

export const mutations = {
  SET_WS_DATA (state, { key, value }) {
    const data = JSON.parse(value)
    Vue.set(state[key], data.currency, data)
    if (key === 'liveData') {
      const length = state.bufferBigData.live[data.currency].data.length
      const limit = 200

      length >= limit && state.bufferBigData.live[data.currency].data.shift()
      length >= limit && state.bufferBigData.live[data.currency].lables.shift()

      state.bufferBigData.live[data.currency].data.push(data.kraken_buy_price)
      state.bufferBigData.live[data.currency].lables.push(data.time)
    }
  },
  UPDATE_BUFFER_BIG_DATA (state, { key, coin, value }) {
    Vue.set(state.bufferBigData[key][coin], 'data', value.map(item => item.buy_price))
    Vue.set(state.bufferBigData[key][coin], 'lables', value.map(item => item.time))
  },
  LOADING (state, bool) {
    state.loading = bool
  },
  CHANGE_CURRENT_COIN (state, coin) {
    state.currentCurrencyType = coin
  },
  LOGOUT_WS (state) {
    for (const key in storeSate) {
      if (Object.hasOwnProperty.call(state, key)) {
        Vue.set(state, key, storeSate[key])
      }
    }
  }
}
