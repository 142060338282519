import './assets/styles/main.scss'

import Vue from 'vue'

import VTooltip from 'v-tooltip'
import Paginate from 'vuejs-paginate'

import './registerServiceWorker'
import i18n from './plugins/i18n'

import router from './router'
import store from './store'

import App from './App.vue'

Vue.use(VTooltip)

Vue.config.productionTip = false

Vue.component('Paginate', Paginate)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
