<template>
  <div class="empty-layout h-full">
    <div class="flex h-full">

      <SidebarLogin />

      <div class="wrapper login">

        <keep-alive>
          <router-view />
        </keep-alive>

      </div>
    </div>
  </div>
</template>

<script>
import SidebarLogin from '@/components/app/SidebarLogin'

export default {
  name: 'EmptyLayout',
  components: {
    SidebarLogin
  }
}
</script>
