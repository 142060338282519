<template>
<div class="bg-gray wrapper-sidebar">
  <div class="sidebar sidebar-admin">
      <router-link to="/" class="logo">
        <img src="img/icons/Asset-1@72x-8.png" alt="LOGO">
      </router-link>
      <ul>
        <li v-for="(link, idx) in links"
          :key="idx"
          @click.stop="linkParent(idx)"
          class="sidebar-item flex items-center"
          :class="{active: linkActive === idx}"
        >
          <span class="relative">
            <i :class="'icon-' + link.icon"></i>
            {{ $t(link.title) }}
            <i v-if="link.children" class="icon-arrow"></i>
          </span>
          <div class="children">
            <router-link
              v-for="child in link.children"
              :key="child.url"
              class="block"
              active-class="active"
              :to="child.url"
              :exact="link.exact"
            >
              {{ $t(child.title) }}
            </router-link>
          </div>
        </li>
      </ul>
      <LogOut/>
    </div>
  </div>
</template>

<script>
import LogOut from '@/components/buttons/LogOut.vue'

export default {
  name: 'SidebarAdmin',
  components: {
    LogOut
  },
  data: () => ({
    links: [
      {
        title: 'navbar.assets.funding_details',
        icon: 'funding',
        exact: true,
        children: [
          { title: 'navbar.order_history.spot', url: '/spot' },
          { title: 'navbar.order_history.futures', url: '/future' }
        ]
      },
      {
        title: 'navbar.assets.balances',
        icon: 'trade',
        children: [
          { title: 'sidebar_admin.deposit_history', url: '/deposit-history' },
          { title: 'sidebar_admin.fiats', url: '/fiats' },
          { title: 'overview.red_envelopes', url: '/envelopes' },
          { title: 'general.transfer', url: '/transfer' }
        ]
      },
      {
        title: 'navbar.assets.order_details',
        icon: 'news',
        children: [
          { title: 'navbar.order_history.spot', url: '/spot' },
          { title: 'navbar.order_history.futures', url: '/future' }
        ]
      }
      // {
      //   title: '客服',
      //   url: '/support',
      //   icon: 'support'
      // }
    ],
    linkActive: null
  }),
  methods: {
    linkParent (idx) {
      this.linkActive = this.linkActive !== idx ? idx : null
    }
  }
}
</script>
