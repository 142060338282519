<template>
  <nav class="navbar">
    <div v-if="title" class="navbar-left title flex items-center">
      <button v-if="!$route.meta.admin" class="btn md:mr-8" @click="$router.go(-1)"><i class="icon-arrow mr-3"></i>Back</button>
      <i v-if="account" class="icon-burger lg:hidden block mr-4" @click="$emit('show')"></i>
      <h4 class="text-white font-roboto">{{ title }}</h4>
    </div>
    <div
      v-else
      class="navbar-left md:flex hidden items-center"
    >
      <h4>{{ $t('navbar.title') }}</h4>
      <div class="navbar-left__item xl:ml-10 ml-5 xl:mr-12 mr-5 lg:flex hidden items-center">
        <i class="icon-sound"></i>
        <h5 class="text-gray-light ml-2.5">{{ $t('navbar.earn') }}</h5>
      </div>
      <h5 class="text-blue lg:block hidden">{{ $t('navbar.details') }}</h5>
    </div>

    <ul class="navbar-right md:flex hidden ml-auto items-center">

      <AppSelect
        class="center"
        :lable="'navbar.assets.title_name'"
        :options="assetsOptions"
        @select="selectAssets"
      />

      <AppSelect
        class="ml-7 mr-10 center"
        :lable="'navbar.order_history.title_name'"
        :options="orderOptions"
        @select="selectOrder"
      />

      <li class="navbar-right__item mr-9">
        <a href="#">
          <i class="icon-alarm"></i>
          <div class="notification"></div>
        </a>
      </li>
      <li class="navbar-right__item mr-9"><a href="#"><i class="icon-email"></i></a></li>
      <router-link to="/support">
        <i class='icon-support pr-1'></i>{{ $t('navbar.support') }}
      </router-link>
      <!-- <li class="navbar-right__item navbar-account"
          cy="user-options-dd"
        :class="{'active': dropdown}"
        v-outside="accountOutside"
        @click="dropdown = !dropdown"
      >
        <div class="flex items-center cursor-pointer">
          <div class="navbar-account__img">
            <img src="img/user.png" alt="">
          </div>
          <span class="name">{{ name }}</span>
          <i class="icon-arrow"></i>
        </div>

        <ul class="dropdown">
          <li class="dropdown-item"><a href="#">Deposit</a></li>
          <li class="dropdown-item"><a href="#">Withdraw</a></li>
          <li class="divider"></li>
        </ul>
      </li> -->

      <AppSelect
        class="ml-7 right"
        :lable="language"
        :i18n="false"
        :options="languageOptions"
        @select="selectLanguage"
      />
    </ul>
  </nav>
</template>

<script>
import clickOutsideMixin from '@/mixins/clickOutside.mixin'
import AppSelect from '@/components/ui/AppSelect'

export default {
  name: 'Navbar',
  props: ['account'],
  mixins: [clickOutsideMixin],
  data: () => ({
    name: 'Kevine Harry',
    language: 'EN',
    languageOptions: [
      { name: '简体中文', code: 'zh' },
      { name: 'English', code: 'en' }
    ],
    assetsOptions: [
      { name: 'navbar.assets.funding_details', path: '/details' },
      { name: 'navbar.assets.balances', path: '/deposit' },
      { name: 'navbar.assets.order_details', path: '/order' }
    ],
    orderOptions: [
      { name: 'navbar.order_history.futures', path: '/future' },
      { name: 'navbar.order_history.spot', path: '/spot' }
    ],
    dropdown: false,
    title: null
  }),
  components: {
    AppSelect
  },
  beforeMount () {
    const locale = window.sessionStorage.getItem('locale') || 'en'
    this.language = locale.toUpperCase()
    this.$i18n.locale = locale
  },
  methods: {
    accountOutside () {
      this.dropdown = false
    },
    selectLanguage (option) {
      this.language = option.code.toUpperCase()
      this.$i18n.locale = option.code
      window.sessionStorage.setItem('locale', option.code)
    },
    selectAssets (option) {
      this.$router.push(option.path)
    },
    selectOrder (option) {
      this.$router.push(option.path)
    }
    // handleLogout () {
    //   this.$store.dispatch('logout')
    // }
  },
  watch: {
    $route () {
      this.title = this.$route.meta.title
    }
  },
  mounted () {
    this.title = this.$route.meta.title
  }
}
</script>
