<template>
  <div class="sidebar sidebar-account bg-gray">
    <router-link class="logo" to="/">
      <img src="img/icons/Asset-1@72x-8.png" alt="LOGO">
    </router-link>
    <i class="icon-plus" @click="$emit('close')"></i>
    <router-link v-for="link in links" :key="link.url" class="sidebar-item flex items-center" active-class="active"
      :to="link.url" :exact="link.exact">
      <i :class="'icon-' + link.icon"></i>{{ $t(link.title) }}
    </router-link>
    <LogOut/>
    </div>
</template>

<script>
import LogOut from '@/components/buttons/LogOut.vue'

export default {
  components: {
    LogOut
  },
  data: () => ({
    links: [
      { title: 'sidebar_account.overview', url: '/overview', icon: 'overview', exact: true },
      { title: 'sidebar_account.settings', url: '/settings', icon: 'settings' },
      { title: 'sidebar_account.security', url: '/security', icon: 'security' },
      { title: 'sidebar_account.copying_trades', url: '/copying-trades', icon: 'news' },
      { title: 'sidebar_account.following_trades', url: '/following-trades', icon: 'news' }
      // { title: 'Support Center', url: '/support', icon: 'support' }
    ]
  })
}
</script>
