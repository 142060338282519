const wsUrl = 'wss://test-24mex.happylucky.online:9001/ws'

export const currencyLive = `${wsUrl}/currency_live`
export const currencySummary = `${wsUrl}/currency_summary`
export const optionsLive = `${wsUrl}/options_live`

export const currencyReport = '/api/currency_report'

export const depositRequest = '/api/deposit_request'

export const login = '/api/get_token'
export const signup = '/api/auth'
export const forgetPassword = '/api/forget_password'
export const setPassword = '/api/set_password'
export const userInfo = '/api/user_info'

export const receiveVerificationCode = '/api/recieve_verification_code'
export const sendVerificationCode = '/api/send_verification_code'

export const createOption = '/api/create_option'
export const listOptions = '/api/list_options'
export const closeOption = '/api/close_option'
