<template>
  <div>
    <Loader v-if="loading" />
    <div v-else class="app-main-layout flex" :class="{'show-sidebar': showSidebar}">

      <SidebarAccount v-if="showSidebarAccount" @close="showSidebar = false" />
      <SidebarAdmin v-else-if="showSidebarAdmin" />
      <Sidebar v-else />
      <BottomBar v-if="isMobile" />

      <main class="app-content" :class="{'app-content__account': showSidebarAccount}">
        <Navbar :account="showSidebarAccount" @show="showSidebar = true" />
        <div class="app-page sm:pb-0">
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/app/Navbar'
import Sidebar from '@/components/app/Sidebar'
import BottomBar from '@/components/app/BottomBar.vue'
import SidebarAdmin from '@/components/app/SidebarAdmin'
import SidebarAccount from '@/components/app/SidebarAccount'

export default {
  name: 'main-layout',
  data: () => ({
    loading: false,
    sidebarAdmin: ['Spot', 'DepositHistory'],
    sidebarAccount: ['Overview', 'Security'],
    showSidebar: false,
    windowWidth: window.innerWidth
  }),
  components: {
    Navbar,
    Sidebar,
    SidebarAdmin,
    SidebarAccount,
    BottomBar
  },
  created () {
    this.$store.dispatch('get_live_currency')
    this.$store.dispatch('get_summary_currency')
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    showSidebarAdmin () {
      return this.sidebarAdmin.includes(this.$route.name)
    },
    showSidebarAccount () {
      return this.sidebarAccount.includes(this.$route.name)
    },
    isMobile () {
      return this.windowWidth <= 991
    }
  },
  watch: {
    $route (to, from) {
      this.showSidebar = false
    }
  }
}
</script>
