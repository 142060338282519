import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './locales/en.json'
import zh from './locales/zh.json'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = [{ en }, { zh }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key]
  })
  return messages
}

export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})
