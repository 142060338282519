import { listOptions, createOption, optionsLive, closeOption } from '../api'
import axios from 'axios'
import Vue from 'vue'

export const state = {
  data: {},
  listOptionsData: [],
  wsOptions: undefined,
  tradeSubscribers: Object.create(null)
}

export const actions = {
  async list_options ({ state, commit, dispatch }) {
    try {
      const token = window.sessionStorage.getItem('token')

      const { data } = await axios.post(listOptions, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      commit('SET_OPTIONS_LIST_DATA', data.options_array)
      await dispatch('get_live_options')
      // console.log(data)
    } catch (err) {
      console.log(err)
    }
  },

  async create_option ({ commit }, payload) {
    const token = window.sessionStorage.getItem('token')
    return await axios.post(createOption, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  },
  async close_option ({ commit }, uuidOption) {
    const token = window.sessionStorage.getItem('token')
    return await axios.post(closeOption, { uuid: uuidOption }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  },

  async get_live_options ({ state, commit, dispatch }) {
    try {
      const token = window.sessionStorage.getItem('token')
      const url = `${optionsLive}?token=${token}`

      await dispatch('ws_options_list_unsubscribe')

      state.wsOptions = new WebSocket(url)

      state.wsOptions.onmessage = ev => {
        ev.data?.length > 100 && commit('SET_WS_OPTIONS_LIST_DATA', ev.data)
      }
      state.wsOptions.onerror = ev => {
        console.log('error')
      }
      state.wsOptions.onopen = ev => {
        state.listOptionsData.forEach(element => {
          if (!element.closed_on) {
            state.wsOptions.send(JSON.stringify({ event: 'subscribe', option_uuid: element.option_project_uuid }))
          }
        })
      }
      state.wsOptions.onclose = ev => {
        console.log('close')
        return true
      }
    } catch (err) {
      console.log(err)
    }
  },
  ws_options_list_unsubscribe ({ state }) {
    if (state.wsOptions && state.wsOptions.readyState !== 3) {
      state.listOptionsData.forEach(element => {
        if (!element.closed_on) {
          state.wsOptions.send(JSON.stringify({ event: 'unsubscribe', option_uuid: element.option_project_uuid }))
        }
      })
      state.wsOptions.close()
    }
  }
}

export const mutations = {
  SET_OPTIONS_LIST_DATA (state, arr) {
    Vue.set(state, 'listOptionsData', arr)
  },
  SET_WS_OPTIONS_LIST_DATA (state, obj) {
    const tmp = JSON.parse(obj)
    Vue.set(state.tradeSubscribers, tmp.option_project_uuid, tmp)
  }
}
