import { login, signup, forgetPassword, setPassword, userInfo, receiveVerificationCode, sendVerificationCode } from '../api'
import axios from 'axios'
import Vue from 'vue'
import router from '../../router'
import jwtDecode from 'jwt-decode'

const _jwtDecode = (token) => {
  try {
    return jwtDecode(token)
  } catch (error) {
    return undefined
  }
}

// let userDataSStorage = window.sessionStorage.getItem('user')
// userDataSStorage = userDataSStorage ? window.atob(userDataSStorage) : undefined

const ERROR_TOKEN_EXPIRED = 'Token has been expired'
const ERROR_USER_NOT_FOUND_TOKEN = 'Error, no user with this token'
const ERROR_USER_NOT_FOUND_BY_CREDENTIALS = 'Error, no user with such credentials'

const ERRORS_MSG = [
  ERROR_TOKEN_EXPIRED,
  ERROR_USER_NOT_FOUND_TOKEN,
  ERROR_USER_NOT_FOUND_BY_CREDENTIALS
]

export const state = function () {
  return {
    isLogged: false,
    // user: userDataSStorage ? JSON.parse(userDataSStorage) : {},
    user: {},
    loginFailed: false,
    loginErrMsg: '',
    securityPage: '',
    errMsg: '',
    logoutTimeout: 0
  }
}

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = 'https://test-24mex.happylucky.online'
}

export const actions = {
  async login ({ state, commit, dispatch }, credentials) {
    try {
      commit('CLEAR_ERR_MSG')
      const { data } = await axios.post(login, credentials)

      window.sessionStorage.setItem('token', data.token)
      window.sessionStorage.setItem('auth', data.two_factor_auth ? 1 : 0)

      if (data.two_factor_auth) {
        await dispatch('send_email_code', data.token)
      } else {
        commit('LOGIN_SUCCESS')
        await router.push('/')
      }
    } catch (err) {
      console.log(err)
      console.log(err.response?.data)
      commit('SET_ERR_MSG', err.response.data ?? err.message)
      // err.response?.status === 400 && commit('FLAG_LOGIN_ERR', err.response?.data)
    }
  },
  async getUserInfo ({ state, commit, dispatch }) {
    try {
      const token = window.sessionStorage.getItem('token')

      const { data } = await axios(userInfo, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      window.sessionStorage.setItem('user', window.btoa(JSON.stringify(data)))
      commit('USER_INFO', data)
      await dispatch('logoutWhenExpired', token)
    } catch (err) {
      console.log(err.response.data)
      await dispatch('logout')
    }
  },
  async signup ({ commit, dispatch }, { email, password }) {
    try {
      commit('CLEAR_ERR_MSG')
      const { data } = await axios.post(signup, {
        email,
        password,
        two_factor_auth: true,
        is_admin: false
      })
      window.sessionStorage.setItem('auth', 1)

      dispatch('send_email_code', data.token)
    } catch (err) {
      commit('SET_ERR_MSG', err.response.data)
      console.log('err signup', err.response)
    }
  },
  async forgetPassword ({ commit }, email) {
    try {
      commit('CLEAR_ERR_MSG')
      const { data } = await axios.post(forgetPassword, { email })
      window.sessionStorage.setItem('token', data.token)
      window.sessionStorage.setItem('auth', 1)
      commit('SWITCH_SECURITY_PAGE', 'SecurityCode')
    } catch (err) {
      commit('SET_ERR_MSG', err.response.data)
      console.log('err forget password', err.response)
    }
  },
  async verifyCodeForgetPassword ({ commit, dispatch }, code) {
    try {
      commit('CLEAR_ERR_MSG')
      const token = window.sessionStorage.getItem('token')

      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(sendVerificationCode, {
        verification_code: code
      }, options)
      commit('SWITCH_SECURITY_PAGE', 'NewPassword')
    } catch (err) {
      commit('SET_ERR_MSG', err.response.data)
      console.log('verify code', err.response)
    }
  },
  async setNewPassword ({ commit }, password) {
    try {
      commit('CLEAR_ERR_MSG')
      const token = window.sessionStorage.getItem('token')

      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(setPassword, { password }, options)
      // commit('LOGOUT')
      await router.push('/login')
    } catch (err) {
      commit('SET_ERR_MSG', err.response.data)
      console.log('err signup', err.response)
    }
  },
  async send_email_code ({ commit }, token) {
    try {
      await axios.post(receiveVerificationCode, {
        token
      })
      window.sessionStorage.setItem('token', token)
      commit('SWITCH_SECURITY_PAGE', 'SecurityCode')
      commit('SET_ERR_MSG', '')
    } catch (err) {
      console.log('send email code', err)
    }
  },
  async verify_code ({ commit, dispatch }, code) {
    try {
      commit('CLEAR_ERR_MSG')
      const token = window.sessionStorage.getItem('token')

      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(sendVerificationCode, {
        verification_code: code
      }, options)
      window.sessionStorage.setItem('auth', 0)
      commit('LOGIN_SUCCESS')
      // await dispatch('getUserInfo')
      await router.push('/')
    } catch (err) {
      commit('SET_ERR_MSG', err.response.data)
      console.log('verify code', err.response)
    }
  },
  async logout ({ commit, dispatch }) {
    dispatch('ws_dashboard_unsubscribe')
    dispatch('ws_options_list_unsubscribe')
    commit('LOGOUT')
    await router.push('/login')
  },
  auth ({ commit, dispatch }) {
    const token = window.sessionStorage.getItem('token')
    const auth = window.sessionStorage.getItem('auth')

    if (!token) return false

    const DTO = _jwtDecode(token)
    if (DTO) {
      let time = DTO.exp * 1000 - Date.now()
      time = time < 0
      if (!time) {
        if (Number(auth) === 1) return false
        return true
      }
      commit('LOGOUT')
      return false
    }
  },
  logoutWhenExpired ({ commit, dispatch }, token) {
    const DTO = _jwtDecode(token)
    if (DTO) {
      let time = DTO.exp * 1000 - Date.now()
      time = time < 0 ? 0 : time
      commit('setLogoutTimeout', setTimeout(() => { dispatch('logout') }, time))
    }
  }
}

export const mutations = {
  USER_INFO (state, obj) {
    Vue.set(state, 'user', obj)
  },
  CLEAR_ERR_MSG (state) {
    Vue.set(state, 'errMsg', '')
    Vue.set(state, 'loginErrMsg', '')
  },
  RESET_LOGIN_SIGNUP_FORGET_STATE (state) {
    Vue.set(state, 'securityPage', '')
    Vue.set(state, 'loginFailed', false)
    Vue.set(state, 'loginErrMsg', '')
    Vue.set(state, 'errMsg', '')
  },
  LOGIN_SUCCESS (state) {
    Vue.set(state, 'isLogged', true)
    Vue.set(state, 'loginFailed', false)
  },
  FLAG_LOGIN_ERR (state, msg) {
    Vue.set(state, 'loginFailed', true)
    Vue.set(state, 'loginErrMsg', msg.substring(0, msg.indexOf('|')))
  },
  LOGOUT (state) {
    Vue.set(state, 'isLogged', false)
    state.securityPage = ''
    state.errMsg = ''
    state.user = {}
    window.sessionStorage.removeItem('auth')
    window.sessionStorage.removeItem('token')
    window.sessionStorage.removeItem('user')
    window.sessionStorage.removeItem('locale')
  },
  SWITCH_SECURITY_PAGE (state, page) {
    Vue.set(state, 'securityPage', page)
  },
  SET_ERR_MSG (state, msg) {
    const res = ERRORS_MSG.find(item => msg.includes(item))
    Vue.set(state, 'errMsg', res ?? msg)
  },
  setLogoutTimeout (state, timeoutId) {
    if (state.logoutTimeout !== 0) {
      clearInterval(state.logoutTimeout)
    }
    state.logoutTimeout = timeoutId
  }
}
