<template>
  <div class="select"
    :class="{'active': isActive}"
    v-outside="clickOutside"
    @click="isActive = !isActive"
  >
    <div class="select-lable flex items-center cursor-pointer">
      <slot></slot>
      <span class="name mr-2" :class="opacityName">{{ checki18n(lable) }}</span>
      <span v-if="lableTwo" class="name ml-2">{{ checki18n(lableTwo) }}</span>
      <i v-if="icon" class="icon-arrow"></i>
    </div>

    <ul class="options">
      <li
        v-for="(option, idx) in options"
        :key="idx"
        class="options-item cursor-pointer"
        @click="$emit('select', option)"
      >{{ checki18n(option.name) || checki18n(option) }}</li>
    </ul>
  </div>
</template>

<script>
import clickOutsideMixin from '@/mixins/clickOutside.mixin'

export default {
  name: 'CustomSelect',
  mixins: [clickOutsideMixin],
  props: {
    options: {
      Type: Array
    },
    lable: {
      Type: String
    },
    lableTwo: {
      Type: String
    },
    opacityName: {
      Type: String
    },
    icon: {
      Type: Boolean,
      default: true
    },
    i18n: {
      Type: Boolean,
      default: true
    }
  },
  data: () => ({
    isActive: false
  }),
  methods: {
    checki18n (str) {
      return this.$props.i18n ? this.$t(str) : str
    },
    clickOutside () {
      this.isActive = false
    }
  }
}
</script>
